/* ./styles/globals.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #ffedd5;
}

@font-face {
  font-family: "DigitalDisco";
  src: url("/fonts/DigitalDisco/DigitalDisco.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DigitalDisco";
  src: url("/fonts/DigitalDisco/DigitalDisco-Thin.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "ApfelGrotezk";
  src: url("/fonts/ApfelGrotezk/ApfelGrotezk-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ApfelGrotezk";
  src: url("/fonts/ApfelGrotezk/ApfelGrotezk-Fett.otf");
  font-weight: 600;
  font-style: normal;
}
